import callApi from '../api/services';
import { SectorsPaths } from '../../constants';

export const queryConfigsData = (sector, from, to) =>
  callApi(`${SectorsPaths.SECTORS}/${sector}?from=${from}&to=${to}`, { basePath: window.env.SECTORS_API_BASE_URL });

export const queryPossibleConfs = sectorName => callApi(`${SectorsPaths.POSSIBLE_CONFS}/${sectorName}`, { basePath: window.env.SECTORS_API_BASE_URL });

export const callUpdateSectorsConf = (sectoPlanName, conf, day) =>
  callApi(`${SectorsPaths.UPDATE_PLAN}/${sectoPlanName}?source=USER&day=${day}`, { basePath: window.env.SECTORS_API_BASE_URL, method: 'PUT', body: conf });

export const querySectorsOfConfiguration = (sectorName, conf) =>
  callApi(`${SectorsPaths.POSSIBLE_CONFS}/${sectorName}/${conf}`, { basePath: window.env.SECTORS_API_BASE_URL });

export const querySplitData = (sectoName, confName, remainingSectors, from, to) => callApi(`${SectorsPaths.RELATIONSHIPS}`, { basePath: window.env.SECTORS_API_BASE_URL,
  method: 'POST',
  body: {
    conf_name: confName,
    tfv_to_split: sectoName,
    remaining_tfvs: remainingSectors,
    from,
    to,
  } });
