import {
  SELECT_HOTSPOT,
  CLEAR_SELECTED_HOTSPOT,
  UPDATE_HOTSPOTS_AFTER_WHAT_IF_CREATION,
  UPDATE_HOTSPOTS_AFTER_WHAT_IF_DELETION,
  DECLARING_DCB_HOTSPOT,
  DECLARING_DCB_HOTSPOT_SUCCESS,
  DECLARING_DCB_HOTSPOT_FAILURE,
  DECLARING_DCB_HIDE_ERROR,
  LOADING_TIMELINE_HOTSPOTS_DATA,
  LOADING_TIMELINE_HOTSPOTS_DATA_SUCCESS,
  LOADING_TIMELINE_HOTSPOTS_DATA_FAILURE,
  LOADING_SPLIT_PLAN,
  LOADING_SPLIT_PLAN_SUCCESS,
  LOADING_SPLIT_PLAN_FAILURE,
  CLEAR_SPLIT_PLAN,
} from './actions';

import { HotspotStatus } from '../../constants';
import hotspotsUtil from '../../utils/HotspotsUtil';

const initial = {
  timelineHotspots: { hotspots: [] },
  selectedHotspot: undefined,
  selectedDCBHotspotTrafficLoad: undefined,
  isDeclaringDCBHotspot: false,
  isLoadingSplitHotspots: true,
  splitHotspots: null,
  splitKpis: null,
};

const getSelectedUpdateDCB = (state, dcbHotspots, currentTime) => {
  let { selectedHotspot: updatedHotspotFormated, updateMessage } = state;
  if (state.selectedHotspot && dcbHotspots.dcbDiff) {
    if (dcbHotspots.dcbDiff.newHotspot) {
      updatedHotspotFormated = hotspotsUtil.formatHotspot(dcbHotspots.dcbDiff.newHotspot, currentTime);
    }
    updateMessage = dcbHotspots.dcbDiff.diffMessage;
  }
  return { updatedHotspotFormated, updateMessage };
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_TIMELINE_HOTSPOTS_DATA: {
      const { meta: { requestId, priority } } = action;
      if (priority || !state.requestId) { return { ...state, requestId }; }
      return state;
    }
    case LOADING_TIMELINE_HOTSPOTS_DATA_SUCCESS: {
      const { meta: { requestId } } = action;
      if (requestId !== state.requestId) { return state; }

      const { updateMessage, updatedHotspotFormated } = getSelectedUpdateDCB(state, action.hotspots, action.currentTime);
      const stillSelectingSameHotspot = action.hotspots.dcbDiff && state.selectedHotspot.hotspotId === action.hotspots.dcbDiff.previousUuid;
      return {
        ...state,
        timelineHotspots: action.hotspots,
        selectedHotspot: stillSelectingSameHotspot ? updatedHotspotFormated : state.selectedHotspot,
        updateMessage,
        requestId: undefined,
      };
    }
    case LOADING_TIMELINE_HOTSPOTS_DATA_FAILURE: {
      const { meta: { requestId } } = action;
      if (requestId !== state.requestId) { return state; }
      return { ...state, error: action.error, requestId: undefined };
    }
    case SELECT_HOTSPOT: {
      return {
        ...state,
        timelineHotspots: {
          ...state.timelineHotspots,
          dcbDiff: undefined,
        },
        selectedHotspot: action.hotspot,
        updateMessage: undefined,
      };
    }
    case CLEAR_SELECTED_HOTSPOT: {
      return {
        ...state,
        timelineHotspots: {
          ...state.timelineHotspots,
          dcbDiff: undefined,
        },
        selectedHotspot: undefined,
        updateMessage: undefined,
      };
    }
    case UPDATE_HOTSPOTS_AFTER_WHAT_IF_CREATION: {
      const { sessionId } = action;
      const status = HotspotStatus.ACTIVE;
      const selectedHotspot = state.selectedHotspot && state.selectedHotspot.hotspotId === action.hotspotId ?
        { ...state.selectedHotspot, status, sessionId } : state.selectedHotspot;
      const hotspots = state.timelineHotspots.hotspots.map(hotspot => (hotspot.uuid === action.hotspotId ?
        { ...hotspot, status, sessionId } : hotspot));
      return { ...state, timelineHotspots: { ...state.timelineHotspots, hotspots }, selectedHotspot };
    }
    case UPDATE_HOTSPOTS_AFTER_WHAT_IF_DELETION: {
      const status = HotspotStatus.NEW;
      const copyAllExceptSessionId = ({ sessionId, ...hotspot }) => hotspot;
      const selectedHotspot = state.selectedHotspot && state.selectedHotspot.sessionId === action.sessionId ?
        { ...copyAllExceptSessionId(state.selectedHotspot), status } : state.selectedHotspot;
      const hotspots = state.timelineHotspots && state.timelineHotspots.hotspots
        && state.timelineHotspots.hotspots.map(hotspot => (hotspot.sessionId === action.sessionId ?
          { ...copyAllExceptSessionId(hotspot), status } : hotspot));
      return { ...state, timelineHotspots: { ...state.timelineHotspots, hotspots }, selectedHotspot };
    }
    case DECLARING_DCB_HOTSPOT: {
      return { ...state, isDeclaringDCBHotspot: true };
    }
    case DECLARING_DCB_HOTSPOT_SUCCESS: {
      return {
        ...state,
        timelineHotspots: {
          ...state.timelineHotspots,
          hotspots: state.timelineHotspots.hotspots.map(dcb =>
            (dcb.uuid === action.hotspotId ? { ...dcb, isDeclared: true } : dcb)),
        },
        selectedHotspot: { ...state.selectedHotspot, isDeclared: true },
        isDeclaringDCBHotspot: false,
      };
    }
    case DECLARING_DCB_HOTSPOT_FAILURE: {
      return { ...state, isDeclaringDCBHotspot: false, declareError: action.error };
    }
    case DECLARING_DCB_HIDE_ERROR: {
      return { ...state, declareError: undefined };
    }
    case LOADING_SPLIT_PLAN: {
      return { ...state, isLoadingSplitHotspots: true };
    }
    case LOADING_SPLIT_PLAN_SUCCESS: {
      return { ...state, isLoadingSplitHotspots: false, splitHotspots: action.splitPlan.hotspots, splitKpis: action.splitPlan.kpis };
    }
    case LOADING_SPLIT_PLAN_FAILURE: {
      return { ...state, isLoadingSplitHotspots: false, error: action.error, splitHotspots: null, splitKpis: null };
    }
    case CLEAR_SPLIT_PLAN: {
      return {
        ...state, isLoadingSplitHotspots: true, splitHotspots: null, splitKpis: null,
      };
    }
    default: {
      return state;
    }
  }
};
