import { quickGuid } from '@atfm/utils';
import { queryTimelineHotspotsData, postDeclareDCBHotspot, querySplitDataPlan } from './services';

export const CLEAR_SPLIT_PLAN = 'CLEAR_SPLIT_PLAN';
export function clearSplitPlan() {
  return {
    type: CLEAR_SPLIT_PLAN,
  };
}

export const LOADING_SPLIT_PLAN = 'LOADING_SPLIT_PLAN';
export function loadingSplitPlan() {
  return {
    type: LOADING_SPLIT_PLAN,
  };
}

export const LOADING_SPLIT_PLAN_SUCCESS = 'LOADING_SPLIT_PLAN_SUCCESS';
export function loadingSplitPlanSuccess(splitPlan) {
  return {
    type: LOADING_SPLIT_PLAN_SUCCESS,
    splitPlan,
  };
}

export const LOADING_SPLIT_PLAN_FAILURE = 'LOADING_SPLIT_PLAN_FAILURE';
export function loadingSplitPlanFailure(error) {
  return {
    type: LOADING_SPLIT_PLAN_FAILURE,
    error,
  };
}

export const LOADING_TIMELINE_HOTSPOTS_DATA = 'LOADING_TIMELINE_HOTSPOTS_DATA';
export function loadingTimelineHotspotsData(priority = false) {
  const requestId = quickGuid();
  return {
    type: LOADING_TIMELINE_HOTSPOTS_DATA,
    meta: { requestId, priority },
  };
}

export const LOADING_TIMELINE_HOTSPOTS_DATA_SUCCESS = 'LOADING_TIMELINE_HOTSPOTS_DATA_SUCCESS';
export function loadingTimelineHotspotsDataSuccess(hotspots, currentTime, requestId) {
  return {
    type: LOADING_TIMELINE_HOTSPOTS_DATA_SUCCESS,
    hotspots,
    currentTime,
    meta: { requestId },
  };
}

export const LOADING_TIMELINE_HOTSPOTS_DATA_FAILURE = 'LOADING_TIMELINE_HOTSPOTS_DATA_FAILURE';
export function loadingTimelineHotspotsDataFailure(error, requestId) {
  return {
    type: LOADING_TIMELINE_HOTSPOTS_DATA_FAILURE,
    error,
    meta: { requestId },
  };
}

export const SELECT_HOTSPOT = 'SELECT_HOTSPOT';
export function selectHotspot(hotspot) {
  return {
    type: SELECT_HOTSPOT,
    hotspot,
  };
}

export const CLEAR_SELECTED_HOTSPOT = 'CLEAR_SELECTED_HOTSPOT';
export function clearSelectedHotspot() {
  return {
    type: CLEAR_SELECTED_HOTSPOT,
  };
}

export const UPDATE_HOTSPOTS_AFTER_WHAT_IF_CREATION = 'UPDATE_HOTSPOTS_AFTER_WHAT_IF_CREATION';
export function updateHotspotsAfterWhatIfCreation(whatif) {
  return {
    type: UPDATE_HOTSPOTS_AFTER_WHAT_IF_CREATION,
    hotspotId: whatif.sourceEvent.id,
    sessionId: whatif.sessionId,
  };
}

export const UPDATE_HOTSPOTS_AFTER_WHAT_IF_DELETION = 'UPDATE_HOTSPOTS_AFTER_WHAT_IF_DELETION';
export function updateHotspotsAfterWhatIfDeletion(sessionId) {
  return {
    type: UPDATE_HOTSPOTS_AFTER_WHAT_IF_DELETION,
    sessionId,
  };
}

export const fetchTimelineHotspotsData = (currentTime, firName, from, to, hotspot, sessionId, waitForJob, invalidate, complexity) => (dispatch) => {
  const priority = !!waitForJob;
  const loadingAction = loadingTimelineHotspotsData(priority);
  const { meta: { requestId } } = loadingAction;
  dispatch(loadingAction);
  return queryTimelineHotspotsData(firName, from, to, hotspot, sessionId, waitForJob, invalidate, complexity)
    .then(hotspots => dispatch(loadingTimelineHotspotsDataSuccess(hotspots, currentTime, requestId)))
    .catch(error => dispatch(loadingTimelineHotspotsDataFailure(error, requestId)));
};

export const DECLARING_DCB_HOTSPOT = 'DECLARING_DCB_HOTSPOT';
export function declaringDCBHotspot() {
  return {
    type: DECLARING_DCB_HOTSPOT,
  };
}

export const DECLARING_DCB_HOTSPOT_SUCCESS = 'DECLARING_DCB_HOTSPOT_SUCCESS';
export function declaringDCBHotspotSuccess(hotspotId) {
  return {
    type: DECLARING_DCB_HOTSPOT_SUCCESS,
    hotspotId,
  };
}

export const DECLARING_DCB_HOTSPOT_FAILURE = 'DECLARING_DCB_HOTSPOT_FAILURE';
export function declaringDCBHotspotFailure(error) {
  return {
    type: DECLARING_DCB_HOTSPOT_FAILURE,
    error,
  };
}

export const DECLARING_DCB_HIDE_ERROR = 'DECLARING_DCB_HIDE_ERROR';
export function declaringDCBHideError() {
  return {
    type: DECLARING_DCB_HIDE_ERROR,
  };
}

export const fetchSplitPlan = (confName, sectors, from, to) => (dispatch) => {
  dispatch(loadingSplitPlan());

  return querySplitDataPlan(confName, sectors, from, to)
    .then(data => dispatch(loadingSplitPlanSuccess(data)))
    .catch(error => dispatch(loadingSplitPlanFailure(error)));
};

export const declareDCBHotspot = hotspotId => (dispatch) => {
  dispatch(declaringDCBHotspot());
  return postDeclareDCBHotspot(hotspotId)
    .then(() => dispatch(declaringDCBHotspotSuccess(hotspotId)))
    .catch((error) => {
      dispatch(declaringDCBHotspotFailure(error));
      setTimeout(() => {
        dispatch(declaringDCBHideError());
      }, 5000);
    });
};
