import moment from 'moment';
import SectoConfigList from '../../containers/SectoConfigList/SectoConfigList';
import {
  LOADING_SECTORS_DATA,
  LOADING_SECTORS_DATA_SUCCESS,
  LOADING_SECTORS_DATA_FAILURE,
  LOADING_POSSIBLE_CONFS,
  LOADING_POSSIBLE_CONFS_SUCCESS,
  LOADING_POSSIBLE_CONFS_FAILURE,
  UPDATING_SECTORS_CONF,
  UPDATING_SECTORS_CONF_SUCCESS,
  UPDATING_SECTORS_CONF_FAILURE,
  SECTOR_EDIT_ERROR,
  CLEAR_SECTOR_EDIT_ERROR,
  SELECTED_CONFIG,
  CLEAR_SELECTED_CONFIG,
  CHANGED_CURRENT_PLAN,
  LOADING_SPLIT_DATA,
  LOADING_SPLIT_DATA_SUCCESS,
  LOADING_SPLIT_DATA_FAILURE,
  CLEAR_SPLIT_DATA,
  SELECT_SECTO,
  CLEAR_SECTO,
} from './actions';

export default (state = {
  sectorEditError: false,
  sectorEditErrorMessage: '',
  isSavingConfigs: false,
  selectedConfigData: null,
  currentPlan: null,
  splitData: null,
  selectedSecto: undefined,
}, action = {}) => {
  switch (action.type) {
    case LOADING_SECTORS_DATA: {
      return state;
    }
    case LOADING_SECTORS_DATA_SUCCESS: {
      return { ...state, data: action.sectorsData };
    }
    case LOADING_SECTORS_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    case LOADING_POSSIBLE_CONFS: {
      return state;
    }
    case LOADING_POSSIBLE_CONFS_SUCCESS: {
      return { ...state, possibleConfs: action.possibleConfs.sort() };
    }
    case LOADING_POSSIBLE_CONFS_FAILURE: {
      return { ...state, error: action.error };
    }
    case SELECT_SECTO: {
      return { ...state, selectedSecto: action.sectoData };
    }
    case CLEAR_SECTO: {
      return { ...state, selectedSecto: undefined };
    }
    case CLEAR_SPLIT_DATA: {
      return { ...state, splitData: null };
    }
    case LOADING_SPLIT_DATA: {
      return state;
    }
    case LOADING_SPLIT_DATA_SUCCESS: {
      return { ...state, splitData: action.splitData };
    }
    case LOADING_SPLIT_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    case UPDATING_SECTORS_CONF: {
      return { ...state, isSavingConfigs: true };
    }
    case UPDATING_SECTORS_CONF_SUCCESS: {
      const prevConfs = state.data.confs;

      // only one day of configurations is received
      const { confs } = action.sectorsData;
      const sectoPlanDay = SectoConfigList.calcStartTimesList(confs);

      // filter out the old configurations of the received day
      const filteredConfs = prevConfs.filter((conf) => {
        const confDayToFilter = moment.utc(conf.from).hour(0).minute(0).valueOf();
        return confDayToFilter !== sectoPlanDay[0];
      });

      const updatedConf = filteredConfs.concat(confs).sort((a, b) => a.from - b.from);

      const updatedData = { ...action.sectorsData, confs: updatedConf };
      return { ...state, data: updatedData, isSavingConfigs: false };
    }
    case UPDATING_SECTORS_CONF_FAILURE: {
      return { ...state, error: action.error, isSavingConfigs: false };
    }
    case SECTOR_EDIT_ERROR: {
      return { ...state, sectorEditError: true, sectorEditErrorMessage: action.errorMessage };
    }
    case CLEAR_SECTOR_EDIT_ERROR: {
      return { ...state, sectorEditError: false, sectorEditErrorMessage: '' };
    }
    case SELECTED_CONFIG: {
      return { ...state, selectedConfigData: action.selectedConfigData };
    }
    case CLEAR_SELECTED_CONFIG: {
      return { ...state, selectedConfigData: null };
    }
    case CHANGED_CURRENT_PLAN: {
      const newPlanWithSectors = action.newPlan.map((conf) => {
        const confAlreadyInOldPlan = state.data.confs.find(realConf => realConf.conf_name === conf.conf_name);
        let retrieveSectorsFromOriginalPlan = confAlreadyInOldPlan ? confAlreadyInOldPlan.sectors : undefined;

        if (retrieveSectorsFromOriginalPlan === undefined && state.currentPlan) {
          const confAlreadyInCurrentPlan = state.currentPlan.find(realConf => realConf.conf_name === conf.conf_name);
          retrieveSectorsFromOriginalPlan = confAlreadyInCurrentPlan ? confAlreadyInCurrentPlan.sectors : undefined;
        }

        const keepSectors = conf.sectors ? conf.sectors : retrieveSectorsFromOriginalPlan;
        return { ...conf, sectors: keepSectors };
      });
      return { ...state, currentPlan: newPlanWithSectors };
    }
    default: {
      return state;
    }
  }
};
