export const selectSectors = state => state.sectorsData;

export const selectConfigsData = state => selectSectors(state).data;

export const selectPossibleConfs = state => selectSectors(state).possibleConfs;

export const selectIsSavingConfig = state => selectSectors(state).isSavingConfigs;

export const selectSectorEditError = state => selectSectors(state).sectorEditError;

export const selectSelectedConfig = state => selectSectors(state).selectedConfigData;

export const selectsectorEditErrorMessage = state => selectSectors(state).sectorEditErrorMessage;

export const selectCurrentPlan = state => selectSectors(state).currentPlan;

export const selectSelectedSecto = state => selectSectors(state).selectedSecto;

export const selectSplitData = state => selectSectors(state).splitData;
