import callApi from '../api/services';
import { FlowPaths, HotspotsTypes, TimelinePaths, HotspotsPaths } from '../../constants';

// TODO: timeline endpoint with selected hotspot currently only works for DCB and Complex hotspots
export const queryTimelineHotspotsData = (firName, from, to, selectedHotspot, sessionId, waitForJob, invalidate, complexity) => {
  const params = { from, to, complexity };

  if (selectedHotspot && (selectedHotspot.type === HotspotsTypes.DCB || selectedHotspot.type === HotspotsTypes.COMPLEX)) {
    params.selectedHotspot = selectedHotspot.uuid;
  }

  if (sessionId) {
    params.sessionId = sessionId;
  }

  if (waitForJob !== undefined) {
    params.waitForJob = waitForJob;
  }

  if (invalidate !== undefined) {
    params.invalidate = invalidate;
  }

  const queryParams = Object.entries(params).map(([k, v]) => `${k}=${v}`).join('&');
  return callApi(`${TimelinePaths.TIMELINE}/${firName}?${queryParams}`, { basePath: window.env.TIMELINE_API_BASE_URL });
};

export const postDeclareDCBHotspot = hotspotId => callApi(
  `${FlowPaths.DECLARE}/${hotspotId}`,
  { basePath: window.env.FLOW_API_BASE_URL, method: 'POST' },
);

export const querySplitDataPlan = (confName, sectors, from, to) => callApi(
  `${HotspotsPaths.PLAN}`,
  { basePath: window.env.HOTSPOTS_API_BASE_URL,
    method: 'POST',
    body: {
      confs: [
        {
          conf_name: confName,
          from,
          to,
          sectors,
        },
      ],
    } },
);
