import callApi from '../api/services';
import { UserInfoPaths, XuningPaths } from '../../constants';

export const queryUserInfo = () =>
  callApi(UserInfoPaths.USERINFO, { basePath: window.env.USERINFO_API_BASE_URL })
    .then((data) => {
      if (data) {
        return {
          uid: data.uid,
          area:
          [
            [data.area.min.lat, data.area.min.lon],
            [data.area.max.lat, data.area.max.lon],
          ],
          entryStep: data.entry_step,
          occStep: data.occ_step,
          entryDuration: data.entry_duration,
          occDuration: data.occ_duration,
          tfv: data.tfv_name,
          acc: data.acc_name,
          secto: data.secto_plan_name,
          neighbours: data.neighbours,
        };
      }
      return null;
    });

export const queryForecastPeriod = () => callApi(
  `${XuningPaths.FORECAST}`,
  { basePath: window.env.XUNING_API_BASE_URL },
);
