/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import moment from 'moment';
import isEqual from 'lodash/isEqual';

import { formatedToTimeStamp, timestampToDate, validateTime } from '@atfm/utils';
import { SectorValidationMessages, SectorPlanEditErrorMessages, TimerIntervals } from '../../constants';
import './SectoConfigList.css';
import SectoConfigComponent from '../../components/SectoConfig/SectoConfig';
import InsertNewConfig from '../../components/SectoConfig/InsertNewConfig';
import { selectConfigsData, selectPossibleConfs, selectIsSavingConfig } from '../../store/sectors/selectors';
import { updateSectorsConf, sectorEditError, clearSectorEditError, configSelected, clearConfigSelected, changedCurrentPlan } from '../../store/sectors/actions';
import { selectTimeData } from '../../store/time/selectors';

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const MINIMUM_INSERT_TIME_MILLISECONDS = 30 * 60 * 1000;

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  sectoPlan: selectConfigsData(state),
  possibleConfs: selectPossibleConfs(state),
  isSavingConfigs: selectIsSavingConfig(state),
  currentTime: selectTimeData(state),
});

const mapDispatchToProps = dispatch => ({
  saveChangesAction: (planName, plan, currDay) => dispatch(updateSectorsConf(planName, plan, currDay)),
  sectorEditError: error => dispatch(sectorEditError(error)),
  clearSectorEditError: () => dispatch(clearSectorEditError()),
  configSelected: d => dispatch(configSelected(d)),
  clearConfigSelected: () => dispatch(clearConfigSelected()),
  changedCurrentPlan: newPlan => dispatch(changedCurrentPlan(newPlan)),
});

export class SectoConfigListComponent extends Component {
  // Creates a list with the 00:00 timestamp of each different day present in the configuration
  static calcStartTimesList = confs => confs.reduce((startTimesList, value) => {
    const fromStartOfDay = moment.utc(value.from).hour(0).minute(0).valueOf();
    if (!startTimesList.includes(fromStartOfDay)) {
      return startTimesList.concat(fromStartOfDay);
    }
    return startTimesList;
  }, [])

  static createSectorEntry = (state, index, conf, time) => ({
    id: state ? index + state.rowIdGenerator : index,
    isValid: true,
    validFrom: true,
    validFromMessage: '',
    validTo: true,
    validToMessage: '',
    isEditing: false,
    isHovering: false,
    isPastConf: !(conf.from > time || conf.to > time),
    isCurrent: !(conf.from > time || conf.to < time),
    isClicked: !(conf.from > time || conf.to < time),
    // localData holds the current edited saved data (important for the individual row cancel button)
    localData: { conf_name: conf.conf_name, from: conf.from, to: conf.to },
    // currentData holds the current data in the input and select fields
    currentData: { conf_name: conf.conf_name, from: conf.from, to: conf.to },
  });

  static initialState = (props, state) => {
    const time = props.currentTime;
    const initialTime = moment.utc(time)
      .hour(0).minute(0).second(0)
      .millisecond(0)
      .valueOf();
    const confsTotal = props.sectoPlan.confs;
    const confs = confsTotal.filter(conf => (conf.from >= initialTime));
    const startTimesList = SectoConfigListComponent.calcStartTimesList(confs);

    return {
      // listIdGenerator to ensure no new plan list entry has an existant id
      listIdGenerator: state ? state.listIdGenerator + startTimesList.length : startTimesList.length,
      // Plan conf rowIdGenerator to ensure no new plan entry has an existant id
      rowIdGenerator: state ? state.rowIdGenerator + confs.length : confs.length,
      isEditing: false,
      // props.sectoPlan holds the original data retrieved from API, we save it to check when it is updated
      sectoPlan: props.sectoPlan,
      // Will hold version of the plan to compare and check if there are still edits. We can't directly compare with props since they might change
      originalSecto: confs.map(conf => ({ ...conf })),
      // The actual list to be manipuled and used in the rows representation
      lists: startTimesList.map((startTime, index) =>
        ({
          id: state ? index + state.listIdGenerator : index,
          startTime,
          confs: confs
            .filter(conf => moment.utc(conf.from).hour(0).minute(0).valueOf() === startTime)
            .map((conf, confIndex) => SectoConfigListComponent.createSectorEntry(state, confIndex, conf, time)),
        })),
    };
  }

  static getConfFromListsOfConfs = (lists, conditionFunction) => {
    const listFound = lists.find(list => list.confs.some(conditionFunction));
    if (listFound) {
      return listFound.confs.find(conditionFunction);
    }
    return undefined;
  }

  constructor(props) {
    super(props);
    this.state = SectoConfigListComponent.initialState(props, this.state);
  }

  componentDidMount() {
    if (this.state.lists.length > 0) {
      this.props.configSelected(this.getCurrentConf());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const clickedConf = SectoConfigListComponent.getConfFromListsOfConfs(this.state.lists, conf => conf.isClicked);
    if (clickedConf) {
      const previousClicked = SectoConfigListComponent.getConfFromListsOfConfs(prevState.lists, conf => conf.isClicked);
      if (clickedConf.isValid && (clickedConf !== previousClicked)) {
        this.props.configSelected(this.localSelectedData(clickedConf));
      }
    } else if (this.state.lists.length > 0) {
      const currentConf = this.getCurrentConf();
      if (currentConf) {
        this.props.configSelected(currentConf);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearConfigSelected(this.getCurrentConf);
  }

  // If the list is being edited or the plan didn't change
  // return current state, otherwise reset to props to get updates from the store
  static getDerivedStateFromProps(props, state) {
    if ((state && state.isEditing) || isEqual(props.sectoPlan, state.sectoPlan)) return state;
    return SectoConfigListComponent.initialState(props, state);
  }

  getCurrentConf = () => {
    const time = this.props.currentTime;
    const condition = conf => !(conf.currentData.from > time || conf.currentData.to < time);
    const confSelected = SectoConfigListComponent.getConfFromListsOfConfs(this.state.lists, condition);
    return confSelected && this.localSelectedData(confSelected);
  }

  localSelectedData = (confSelected) => {
    const { currentData } = confSelected;
    const { conf_name: confName } = currentData;
    return {
      localData: { ...confSelected.localData, confName: confSelected.localData.conf_name },
      selectedData: { ...currentData, confName, to: currentData.to > currentData.from ? currentData.to : currentData.to + DAY_IN_MILLISECONDS },
    };
  }

  // Create a reduced configuration list from a list of lists and the name of the property to use (localData / currentData)
  reduceListsOfConfs = (lists, field) => lists.reduce((finalPlan, list) =>
    finalPlan.concat(list.confs.map(entry => ({ ...entry[field] }))), []);

  saveChanges = (list) => {
    this.props.saveChangesAction(
      this.props.sectoPlan.name,
      {
        name: this.props.sectoPlan.name,
        confs: list.confs.map(entry => ({ ...entry.localData })),
      },
      list.startTime,
    ).then((res) => {
      if (res.sectorsData) {
        this.setState({ isEditing: false });
      } else {
        this.props.sectorEditError(SectorPlanEditErrorMessages.SAVING_CHANGES_ERROR);
        setTimeout(this.props.clearSectorEditError, TimerIntervals.SECTOR_EDIT_ERROR);
      }
    });
  }

  cancelChanges = () => this.setState(
    prevState => SectoConfigListComponent.initialState(this.props, prevState),
    () => this.props.changedCurrentPlan(this.reduceListsOfConfs(this.state.lists, 'currentData')),
  );

  changeClickStatus = (listId, entryId) => this.setState(prevState => ({
    lists: prevState.lists.map(list => ({
      ...list,
      confs: list.confs.map(entry => (((list.id === listId) && (entry.id === entryId)) ?
        { ...entry, isClicked: true } : { ...entry, isClicked: false })),
    }
    )),
  }));

  changeEditStatus = (listId, entryId, status) => {
    // If we are starting to edit, change status of entry
    if (status) {
      this.setState(prevState => ({
        lists: prevState.lists.map(list => (list.id === listId ?
          { ...list, confs: list.confs.map(entry => (entry.id === entryId ? { ...entry, isEditing: status } : entry)) } : list)),
      }));
    // If we cancel editing, change status of entry, reset validations and copy from localData
    } else {
      this.setState(prevState => ({
        lists: prevState.lists.map(list => (list.id === listId ?
          {
            ...list,
            confs: list.confs.map(entry => (entry.id === entryId ? {
              ...entry,
              isValid: true,
              validFrom: true,
              validFromMessage: '',
              validTo: true,
              validToMessage: '',
              isEditing: status,
              currentData: { ...entry.localData },
            } : entry)),
          } : list)),
      }), () => this.props.changedCurrentPlan(this.reduceListsOfConfs(this.state.lists, 'currentData')));
    }
  }

  validateFromData = (index, value, processedValue, list) => {
    // If it's the first entry of the list FROM needs to be 0000
    if ((index === 0) && (value !== '0000')) {
      return { validFrom: false, validFromMessage: SectorValidationMessages.FROM_VALUE_START_ERROR };
    // FROM can't be higher or equal than TO
    }
    if (index !== list.length - 1 && processedValue >= list[index].localData.to) {
      return { validFrom: false, validFromMessage: SectorValidationMessages.FROM_BIGGER_TO_ERROR };
    // FROM can't be equal or lower then previous entry FROM
    }
    if ((index !== 0) && (processedValue <= list[index - 1].localData.from)) {
      return { validFrom: false, validFromMessage: SectorValidationMessages.FROM_VALUE_ERROR };
    }
    return { validFrom: true, validFromMessage: '' };
  }

  changeFrom = (listId, entryId, value) => this.setState((prevState) => {
    let { validTime: validFrom, validTimeMessage: validFromMessage } = validateTime(value);
    let finalValue = value;
    if (validFrom) {
      const listOfEntry = prevState.lists.find(list => list.id === listId);
      const index = listOfEntry.confs.findIndex(entry => entry.id === entryId);
      const processedValue = formatedToTimeStamp(value, listOfEntry.startTime, value.includes(':'));
      ({ validFrom, validFromMessage } = this.validateFromData(index, value, processedValue, listOfEntry.confs));
      if (validFrom) finalValue = processedValue;
    }
    return {
      lists: prevState.lists.map(list => (list.id === listId ? {
        ...list,
        confs: list.confs.map(entry => (entry.id === entryId ?
          {
            ...entry,
            isValid: validFrom && entry.validTo,
            validFrom,
            validFromMessage,
            currentData: { ...entry.currentData, from: finalValue },
          } : entry
        )),
      } : list)),
    };
  }, () => this.props.changedCurrentPlan(this.reduceListsOfConfs(this.state.lists, 'currentData')));

  validateToData = (index, value, processedValue, list) => {
    // If it's the last entry of the list TO needs to be 0000
    if ((index === list.length - 1) && (value !== '0000')) {
      return { validTo: false, validToMessage: SectorValidationMessages.TO_VALUE_END_ERROR };
    // TO can't be lower or equal than FROM
    }
    if ((index !== list.length - 1) && (processedValue <= list[index].localData.from)) {
      return { validTo: false, validToMessage: SectorValidationMessages.TO_SMALLER_FROM_ERROR };
    // TO can't be equal or higher then following entry TO except for the second to last conf
    }
    if ((index !== list.length - 1) && (index !== list.length - 2) && (processedValue >= list[index + 1].localData.to)) {
      return { validTo: false, validToMessage: SectorValidationMessages.TO_VALUE_ERROR };
    }
    return { validTo: true, validToMessage: '' };
  }

  changeTo = (listId, entryId, value) => this.setState((prevState) => {
    let { validTime: validTo, validTimeMessage: validToMessage } = validateTime(value);
    let finalValue = value;
    if (validTo) {
      const listOfEntry = prevState.lists.find(list => list.id === listId);
      const index = listOfEntry.confs.findIndex(entry => entry.id === entryId);
      const processedValue = formatedToTimeStamp(value, listOfEntry.startTime, value.includes(':'));
      ({ validTo, validToMessage } = this.validateToData(index, value, processedValue, listOfEntry.confs));
      if (validTo) finalValue = processedValue;
    }
    return {
      lists: prevState.lists.map(list => (list.id === listId ? {
        ...list,
        confs: list.confs.map(entry => (entry.id === entryId ?
          {
            ...entry,
            isValid: entry.validFrom && validTo,
            validTo,
            validToMessage,
            currentData: { ...entry.currentData, to: finalValue },
          } : entry
        )),
      } : list)),
    };
  }, () => this.props.changedCurrentPlan(this.reduceListsOfConfs(this.state.lists, 'currentData')));

  changeConfig = (listId, entryId, value) => {
    this.setState(prevState => ({
      lists: prevState.lists.map(list => (list.id === listId ? {
        ...list,
        confs: list.confs.map(entry => (entry.id === entryId ?
          { ...entry, currentData: { ...entry.currentData, conf_name: value } }
          : entry
        )),
      } : list)),
    }), () => this.props.changedCurrentPlan(this.reduceListsOfConfs(this.state.lists, 'currentData')));
  }

  // Named 'commit' since it changes both localData and currentData
  commitPreviousTo = (index, list, value) => {
    const previous = list[index - 1];
    const newList = [...list];
    newList[index - 1] = {
      ...previous,
      validTo: true,
      validToMessage: '',
      localData: { ...previous.localData, to: value },
      currentData: { ...previous.currentData, to: value },
    };
    return newList;
  }

  // Named 'commit' since it changes both localData and currentData
  commitNextFrom = (index, list, value) => {
    const next = list[index + 1];
    const newList = [...list];
    newList[index + 1] = {
      ...next,
      validFrom: true,
      validFromMessage: '',
      localData: { ...next.localData, from: value },
      currentData: { ...next.currentData, from: value },
    };
    return newList;
  }

  mergeEntries = (index, list) => {
    let newList = [...list];
    const mergePrevious = (index !== 0) && list[index].localData.conf_name === list[index - 1].localData.conf_name;
    const mergeNext = (index !== (list.length - 1)) && list[index].localData.conf_name === list[index + 1].localData.conf_name;
    // If both previous and next are to be merged increase the TO of the previous and remove two elements
    if (mergePrevious && mergeNext) {
      newList = this.commitPreviousTo(index, newList, list[index + 1].localData.to);
      newList.splice(index, 2);
      newList[index - 1].isClicked = true;
      return {
        index: index - 1, list: newList, mergePrevious, mergeNext,
      };
    // If the previous is to be merged increase the TO of the previous and remove an element
    }
    if (mergePrevious) {
      newList = this.commitPreviousTo(index, newList, list[index].localData.to);
      newList.splice(index, 1);
      if (index === newList.length) {
        newList[index - 1].isClicked = true;
      } else {
        newList[index].isClicked = true;
      }
      return {
        index: index - 1, list: newList, mergePrevious, mergeNext,
      };
    // If the previous is to be merged increase the FROM of the next and remove an element
    }
    if (mergeNext) {
      newList = this.commitNextFrom(index, newList, list[index].localData.from);
      newList.splice(index, 1);
      newList[index].isClicked = true;
      return {
        index, list: newList, mergePrevious, mergeNext,
      };
    }
    return {
      index, list: newList, mergePrevious, mergeNext,
    };
  }

  deleteEntry = (listId, entryId) => this.setState((prevState) => {
    const listOfEntry = prevState.lists.find(list => list.id === listId);
    // If list only has an element return same state since there must be at least an entry on the list
    if (listOfEntry.confs.length <= 1) {
      return {};
    }
    const index = listOfEntry.confs.findIndex(entry => entry.id === entryId);
    let listOfEntryConfs = [...listOfEntry.confs];
    const element = listOfEntryConfs[index];
    // If the first element is to be deleted, increase the FROM of the next one, delete the element and return
    if (index === 0) {
      listOfEntryConfs = this.commitNextFrom(index, listOfEntryConfs, element.localData.from);
      listOfEntryConfs.splice(index, 1);
    // If previous and next element of the entry to delete have the same conf_name, entries should be merged
    } else if ((index !== (listOfEntryConfs.length - 1)) &&
    (listOfEntryConfs[index - 1].localData.conf_name === listOfEntryConfs[index + 1].localData.conf_name)) {
      listOfEntryConfs = this.commitPreviousTo(index, listOfEntryConfs, listOfEntryConfs[index + 1].localData.to);
      listOfEntryConfs.splice(index, 2);
    // Otherwise increase the TO of the previous element
    } else {
      listOfEntryConfs = this.commitPreviousTo(index, listOfEntryConfs, element.localData.to);
      listOfEntryConfs.splice(index, 1);
    }
    const time = this.props.currentTime;
    const listOfEntryConfsCurrent = listOfEntryConfs.map(conf =>
      ({
        ...conf,
        isPastConf: !(conf.localData.from > time || conf.localData.to > time),
        isCurrent: !(conf.localData.from > time || conf.localData.to < time),
        isClicked: !(conf.localData.from > time || conf.localData.to < time),
      }));
    const newLists = prevState.lists.map(list => (list.id === listId ? { ...list, confs: listOfEntryConfsCurrent } : list));
    const reducedListsToCompare = this.reduceListsOfConfs(newLists, 'localData');
    return { isEditing: !isEqual(reducedListsToCompare, prevState.originalSecto), lists: newLists };
  }, () => this.props.changedCurrentPlan(this.reduceListsOfConfs(this.state.lists, 'currentData')));

  /* eslint-disable object-curly-newline */
  calcInsertIndexChanges = (id, list) => {
    if (id === -1) {
      // Inserting before first entry
      return { index: 0, indexToChange: 0, indexToInsert: 0, elementToChange: list[0] };
    }
    const index = list.findIndex(entry => entry.id === id);
    if (index === list.length - 1) {
      // Inserting at the end of the list
      return { index, indexToChange: index, indexToInsert: index + 1, elementToChange: list[index] };
    }
    // Inserting in the middle of the list
    return { index, indexToChange: index + 1, indexToInsert: index + 1, elementToChange: list[index + 1] };
  }
  /* eslint-enable object-curly-newline */

  calcInsertNewValues = (id, index, list, elementToChange, midnightOfDay) => {
    if (id === -1) {
      // Inserting before first entry
      return {
        newFrom: midnightOfDay + MINIMUM_INSERT_TIME_MILLISECONDS,
        newTo: elementToChange.localData.to,
        newEntryFrom: midnightOfDay,
        newEntryTo: midnightOfDay + MINIMUM_INSERT_TIME_MILLISECONDS,
      };
    }
    if (index === list.length - 1) {
      // Inserting at the end of the list
      return {
        newFrom: elementToChange.localData.from,
        newTo: (midnightOfDay - MINIMUM_INSERT_TIME_MILLISECONDS) + DAY_IN_MILLISECONDS,
        newEntryFrom: (midnightOfDay - MINIMUM_INSERT_TIME_MILLISECONDS) + DAY_IN_MILLISECONDS,
        newEntryTo: midnightOfDay + DAY_IN_MILLISECONDS,
      };
    }
    // Inserting in the middle of the list
    return {
      newFrom: elementToChange.localData.from + MINIMUM_INSERT_TIME_MILLISECONDS,
      newTo: elementToChange.localData.to,
      newEntryFrom: elementToChange.localData.from,
      newEntryTo: elementToChange.localData.from + MINIMUM_INSERT_TIME_MILLISECONDS,
    };
  }

  insertConfig = (listId, entryId) => this.setState((prevState) => {
    const listOfEntry = prevState.lists.find(list => list.id === listId);
    const listOfEntryConfs = [...listOfEntry.confs];
    // eslint-disable-next-line object-curly-newline
    const { index, indexToChange, indexToInsert, elementToChange } = this.calcInsertIndexChanges(entryId, listOfEntryConfs);

    // Checking the element to change still has 30 minutes left to subtract
    const fromToValidate = elementToChange.localData.from;
    let toToValidate = elementToChange.localData.to;
    // If we are inserting at end of list compare with next day 0000
    if (index >= listOfEntryConfs.length - 2) {
      toToValidate = elementToChange.localData.to + DAY_IN_MILLISECONDS;
    }
    if ((toToValidate - MINIMUM_INSERT_TIME_MILLISECONDS) <= fromToValidate) {
      this.props.sectorEditError(SectorPlanEditErrorMessages.UNABLE_TO_INSERT_NEW_ENTRY);
      setTimeout(this.props.clearSectorEditError, TimerIntervals.SECTOR_EDIT_ERROR);
      return { };
    }

    // eslint-disable-next-line object-curly-newline
    const { newFrom, newTo, newEntryFrom, newEntryTo } =
      this.calcInsertNewValues(entryId, index, listOfEntryConfs, elementToChange, listOfEntry.startTime);

    // Updating the element to change depending new entry values
    listOfEntryConfs[indexToChange] = {
      ...elementToChange,
      validFrom: true,
      validFromMessage: '',
      validTo: true,
      validToMessage: '',
      localData: { ...elementToChange.localData, from: newFrom, to: newTo },
      currentData: { ...elementToChange.currentData, from: newFrom, to: newTo },
    };

    const time = this.props.currentTime;

    // Building and inserting the new sector config entry
    const newConfig = {
      id: this.state.rowIdGenerator,
      isValid: true,
      validFrom: true,
      validFromMessage: '',
      validTo: true,
      validToMessage: '',
      isEditing: true,
      isHovering: false,
      isCurrent: false,
      isPastConf: !(newEntryFrom > time || newEntryTo > time),
      isClicked: false,
      currentData: { conf_name: this.props.possibleConfs[0], from: newEntryFrom, to: newEntryTo },
      localData: { conf_name: this.props.possibleConfs[0], from: newEntryFrom, to: newEntryTo },
    };
    listOfEntryConfs.splice(indexToInsert, 0, newConfig);

    const listOfEntryConfsCurrent = listOfEntryConfs.map(conf =>
      ({ ...conf, isCurrent: !(conf.localData.from > time || conf.localData.to < time) }));
    const newLists = prevState.lists.map(list => (list.id === listId ? { ...list, confs: listOfEntryConfsCurrent } : list));
    return { rowIdGenerator: prevState.rowIdGenerator + 1, isEditing: true, lists: newLists };
  }, () => this.props.changedCurrentPlan(this.reduceListsOfConfs(this.state.lists, 'currentData')));

  confirmEdit = (listId, entryId) => this.setState((prevState) => {
    const listOfEntry = prevState.lists.find(list => list.id === listId);
    let index = listOfEntry.confs.findIndex(entry => entry.id === entryId);
    const element = listOfEntry.confs[index];
    const changedFrom = element.currentData.from !== element.localData.from;
    const changedTo = element.currentData.to !== element.localData.to;
    const changedConf = element.currentData.conf_name !== element.localData.conf_name;
    let listOfEntryConfs = [...listOfEntry.confs];
    // 'Commiting' the changes in the element and toggling isEditing off
    listOfEntryConfs[index] = { ...element, isEditing: false, localData: { ...element.currentData } };
    // If there are no changes just return
    if (!changedFrom && !changedTo && !changedConf) {
      const newList = prevState.lists.map(list => (list.id === listId ? { ...list, confs: listOfEntryConfs } : list));
      return { lists: newList };
    }
    // Calling merge function to handle equal adjacent conf names
    let mergePrevious = false;
    let mergeNext = false;
    if (changedConf) {
      ({
        index, list: listOfEntryConfs, mergePrevious, mergeNext,
      } = this.mergeEntries(index, listOfEntryConfs));
    }
    // 'Commiting' the changes in the previous element TO if it's not the first and entry was not merged
    if ((!mergePrevious) && changedFrom && (index !== 0)) {
      listOfEntryConfs = this.commitPreviousTo(index, listOfEntryConfs, element.currentData.from);
    }
    // 'Commiting' the changes in the next element FROM if it's not the last and entry was not merged
    if ((!mergeNext) && changedTo && (index !== listOfEntryConfs.length - 1)) {
      listOfEntryConfs = this.commitNextFrom(index, listOfEntryConfs, element.currentData.to);
    }
    const time = this.props.currentTime;
    const listOfEntryConfsCurrent = listOfEntryConfs.map(conf =>
      ({ ...conf, isCurrent: !(conf.localData.from > time || conf.localData.to < time) }));
    const newLists = prevState.lists.map(list => (list.id === listId ? { ...list, confs: listOfEntryConfsCurrent } : list));
    const reducedListsToCompare = this.reduceListsOfConfs(newLists, 'localData');
    return { isEditing: !isEqual(reducedListsToCompare, prevState.originalSecto), lists: newLists };
  }, () => this.props.changedCurrentPlan(this.reduceListsOfConfs(this.state.lists, 'currentData')));

  renderListEntry = (listId, entry) => (
    <Fragment key={entry.id}>
      <SectoConfigComponent
        key={entry.id}
        listId={listId}
        entryId={entry.id}
        entry={entry}
        possibleConfs={this.props.possibleConfs}
        changeEditStatus={this.changeEditStatus}
        changeClickStatus={this.changeClickStatus}
        changeFrom={this.changeFrom}
        changeTo={this.changeTo}
        changeConfig={this.changeConfig}
        deleteEntry={this.deleteEntry}
        confirmEdit={this.confirmEdit}
      />
      { !entry.isPastConf && <InsertNewConfig
        key={`new${entry.id}`}
        listId={listId}
        entryId={entry.id}
        insertConfig={this.insertConfig}
      />}
    </Fragment>
  )

  renderLists = lists => lists.map(list => (
    <Fragment key={list.id}>
      <div className="day-separator">
        {timestampToDate(list.startTime)}
      </div>
      {this.isPastConf && <InsertNewConfig listId={list.id} entryId={-1} insertConfig={this.insertConfig} /> }
      {list.confs.map(conf => this.renderListEntry(list.id, conf))}
    </Fragment>
  ))

  render() {
    return (
      <div id="config-list-container">
        <div className="config-header-container">
          <span className="config-header-title">SECTORISATION PLAN</span>
          {this.state.isEditing &&
          <div id="config-list-cancel-save">
            <button
              className="button save"
              onClick={() => this.state.lists.forEach(list => this.saveChanges(list))}
              type="button"
              disabled={this.props.isSavingConfigs}
            >
              {this.props.isSavingConfigs ?
                <i className="fas fa-spinner fa-spin" /> :
                <div>Save changes</div>
              }
            </button>
            <button className="button cancel" onClick={this.cancelChanges} type="button">Cancel</button>
          </div>
          }
        </div>
        <div id="configs-list">
          { this.renderLists(this.state.lists) }
        </div>
      </div>
    );
  }
}

SectoConfigListComponent.propTypes = {
  sectoPlan: PropTypes.shape({
    name: PropTypes.string,
    confs: PropTypes.arrayOf(PropTypes.shape({
      conf: PropTypes.string,
      from: PropTypes.number,
      to: PropTypes.number,
      sectors: PropTypes.arrayOf(PropTypes.string),
    })),
  }),
  clearConfigSelected: PropTypes.func,
  possibleConfs: PropTypes.arrayOf(PropTypes.string),
  saveChangesAction: PropTypes.func.isRequired,
  isSavingConfigs: PropTypes.bool.isRequired,
  sectorEditError: PropTypes.func.isRequired,
  changedCurrentPlan: PropTypes.func.isRequired,
  clearSectorEditError: PropTypes.func.isRequired,
  configSelected: PropTypes.func.isRequired,
  currentTime: PropTypes.number.isRequired,
};

SectoConfigListComponent.defaultProps = {
  sectoPlan: { confs: [] },
  possibleConfs: [],
  clearConfigSelected: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(SectoConfigListComponent);
